/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {  string, func } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PromoQuery from '../../../ContentTypeComponents/PromoQuery';

const useStyles = makeStyles(() => ({
    container: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
    },

    seeDetailsLink: {
        width: '160px',
        '@media screen and (max-width: 500px)': {
            width: '80px',
        },
    },

    dialogContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flex: '0 0 auto',
    },

    closeButton: {
        padding: '16px 24px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiTouchRipple-root span': {
            display: 'none !important',
        },
    },
    dialogContainer: {
        '@media screen and (max-width: 500px)': {
            '& .MuiDialog-paper': {
                margin: '32px 10px',
            },
        },
    },
}));

const SeeDetailLink = ({ promoCode, detailLinkStyle, clickHandler }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleModal = (e, isOpen) => {
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        e.persist();
        e.stopPropagation();
        setOpen(isOpen);
    };

    return (
        <div data-testid="seeDetailLink" className={`${classes.container} ${detailLinkStyle}`}>
            <div className={classes.seeDetailsLink}>
                <span
                    onClick={(e) => { handleModal(e, true); clickHandler('SitewideBanner', 'Click', `See Details: ${promoCode}`); }}
                    role="presentation"
                >
                    <u>See Details</u>
                </span>
            </div>
            {open ? (
                <Dialog className={classes.dialogContainer} open={open} onClick={(e) => handleModal(e, false)}>
                    <div className={classes.dialogContent}>
                        <DialogTitle id="customized-dialog-title">
                            Details for: {promoCode}
                        </DialogTitle>
                        <IconButton data-testid="close-button" className={classes.closeButton} aria-label="close" onClick={(e) => handleModal(e, false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <MuiDialogContent dividers>
                        <Typography gutterBottom>
                            <PromoQuery promocode={promoCode} />
                        </Typography>
                    </MuiDialogContent>
                </Dialog>
            ) : null}
        </div>
    );
};

SeeDetailLink.propTypes = {
    promoCode: string.isRequired,
    detailLinkStyle: string,
    clickHandler: func.isRequired,
};

SeeDetailLink.defaultProps = {
    detailLinkStyle: '',
};

export default SeeDetailLink;
